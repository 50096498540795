export const parlourCategories = {
  women: [
    // {
    //   category: "waxing",
    //   subCategory: "women",
    //   superCategory: "HairStyles",

    //   services: [
    //     {
    //       name: "waxing1",
    //       superCategory: "HairStyles",

    //       category: "waxing",
    //       subCategory: "women",
    //     },
    //     {
    //       name: "waxing2",
    //       superCategory: "HairStyles",

    //       category: "waxing",
    //       subCategory: "women",
    //     },
    //     {
    //       name: "waxing3",
    //       superCategory: "HairStyles",
    //       category: "waxing",
    //       subCategory: "women",
    //     },
    //     {
    //       name: "waxing4",
    //       superCategory: "HairStyles",

    //       category: "waxing",
    //       subCategory: "women",
    //     },
    //     {
    //       name: "waxing5",
    //       superCategory: "HairStyles",

    //       category: "waxing",
    //       subCategory: "women",
    //     },
    //     {
    //       name: "waxing5",
    //       superCategory: "HairStyles",

    //       category: "waxing",

    //       subCategory: "women",
    //     },
    //   ],
    // },
    // {
    //   category: "nailing",
    //   subCategory: "women",
    //   superCategory: "Textures",

    //   services: [
    //     {
    //       name: "nailing1",
    //       superCategory: "Textures",

    //       category: "nailing",
    //       subCategory: "women",
    //     },
    //     {
    //       name: "nailing2",
    //       superCategory: "Textures",

    //       category: "nailing",
    //       subCategory: "women",
    //     },
    //     {
    //       name: "nailing3",
    //       superCategory: "Textures",

    //       category: "nailing",
    //       subCategory: "women",
    //     },
    //   ],
    // },
    // {
    //   category: "facials",
    //   subCategory: "women",
    //   superCategory: "Colours",

    //   services: [
    //     {
    //       name: "facials1",

    //       category: "facials",
    //       subCategory: "women",
    //     },
    //     {
    //       name: "facials2",

    //       category: "facials",
    //       subCategory: "women",
    //     },
    //     {
    //       name: "facials3",

    //       category: "facials",
    //       subCategory: "women",
    //     },
    //   ],
    // },
    // {
    //   category: "regular-spa",
    //   subCategory: "women",
    //   superCategory: "Spas",

    //   services: [
    //     {
    //       name: "massage",
    //       category: "regular-spa",
    //       subCategory: "women",
    //       superCategory: "Spas",
    //     },
    //     {
    //       name: "full-massage",
    //       category: "regular-spa",
    //       subCategory: "women",
    //       superCategory: "Spas",
    //     },
    //   ],
    // },

    {
      superCategory: "regular",
      category: "Hair Essentials",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Hair cut basic",
          category: "Hair Essentials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Hair cut advance",
          category: "Hair Essentials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Creative hair cut",
          category: "Hair Essentials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Sliptends",
          category: "Hair Essentials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Hair wash",
          category: "Hair Essentials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Blow dry setting",
          category: "Hair Essentials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Hair styling",
          category: "Hair Essentials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Ironing",
          category: "Hair Essentials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Tongs",
          category: "Hair Essentials",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Relaxation",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Protein oil",
          category: "Relaxation",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Olive oil",
          category: "Relaxation",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Hair Colouring",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Root touch up",
          category: "Hair Colouring",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Ammonia free",
          category: "Hair Colouring",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Global colouring",
          category: "Hair Colouring",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Streaks",
          category: "Hair Colouring",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Global highlights",
          category: "Hair Colouring",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Hair Treatments",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Hair spa- below shoulder",
          category: "Hair Treatments",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Anti dandruff treatment (takes off dandruff & flakes)",
          category: "Hair Treatments",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Anti hairfall treatment (helps in strengthening roots and hair)",
          category: "Hair Treatments",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Textures",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Keratin treatment",
          category: "Textures",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Smoothening",
          category: "Textures",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Straightening",
          category: "Textures",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Rebonding (depends on length)",
          category: "Textures",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Ear Piercing",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Ear Piercing",
          category: "Ear Piercing",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Nose Piercing",
          category: "Ear Piercing",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Threading",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Eyebrows",
          category: "Threading",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Upper lip",
          category: "Threading",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Chin",
          category: "Threading",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Forehead",
          category: "Threading",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Side locks",
          category: "Threading",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Full face threading",
          category: "Threading",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Waxing",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Upper lip/forehead/chin",
          category: "Waxing",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Underarms",
          category: "Waxing",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Face waxing",
          category: "Waxing",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Full arms",
          category: "Waxing",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Half arms",
          category: "Waxing",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Half legs",
          category: "Waxing",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Full legs",
          category: "Waxing",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Full back",
          category: "Waxing",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Full body",
          category: "Waxing",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Manicure",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Basic manicure",
          category: "Manicure",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Spa manicure",
          category: "Manicure",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Crystal manicure",
          category: "Manicure",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "D-tan manicure",
          category: "Manicure",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Pedicure",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Basic pedicure",
          category: "Pedicure",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Spa pedicure",
          category: "Pedicure",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Crystal pedicure",
          category: "Pedicure",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "D-tan pedicure",
          category: "Pedicure",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Foot massage",
          category: "Pedicure",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Leg massage",
          category: "Pedicure",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "De-Tan Pack",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Face Tan pack",
          category: "De-Tan Pack",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "O3 de-Tan",
          category: "De-Tan Pack",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Full arms Tan pack",
          category: "De-Tan Pack",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Half arms Tan pack",
          category: "De-Tan Pack",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Full legs Tan pack",
          category: "De-Tan Pack",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Half legs Tan pack",
          category: "De-Tan Pack",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Charcoal mask",
          category: "De-Tan Pack",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Fully body de-tan",
          category: "De-Tan Pack",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Clean Ups",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Basic clean-up",
          category: "Clean Ups",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Insta glow clean-up",
          category: "Clean Ups",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Facials",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Fruit Facial",
          category: "Facials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Silver Facial",
          category: "Facials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Diamond Facial",
          category: "Facials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Gold Facial",
          category: "Facials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Anti Aging Facial",
          category: "Facials",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Advance Facials",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Skin Whitening & Brightening",
          category: "Advance Facials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Groom",
          category: "Advance Facials",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "4 layers",
          category: "Advance Facials",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Make-up",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Saree draping",
          category: "Make-up",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Hair do",
          category: "Make-up",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Simple make up",
          category: "Make-up",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Party make up",
          category: "Make-up",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Bridal make up",
          category: "Make-up",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Reception make up",
          category: "Make-up",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Bridal Package",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Saree draping",
          category: "Bridal Package",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Bridal make up",
          category: "Bridal Package",
          subCategory: "women",
        },
        {
          superCategory: "regular",
          name: "Hair style",
          category: "Bridal Package",
          subCategory: "women",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Body Polishing",
      subCategory: "women",
      services: [
        {
          superCategory: "regular",
          name: "Full body polishing",
          category: "Body Polishing",
          subCategory: "women",
        },
      ],
    },
  ],

  men: [
    // {
    //   category: "regular",
    //   subCategory: "men",
    //   superCategory: "HairStyles",

    //   services: [
    //     {
    //       name: "hair-color",
    //       category: "regular",
    //       subCategory: "men",
    //       superCategory: "HairStyles",
    //     },
    //     {
    //       name: "shaving",
    //       category: "regular",
    //       subCategory: "men",
    //       superCategory: "HairStyles",
    //     },
    //     {
    //       name: "hair-cut",
    //       category: "regular",
    //       subCategory: "men",
    //       superCategory: "HairStyles",
    //     },
    //   ],
    // },

    // {
    //   category: "regular-spa",
    //   subCategory: "men",
    //   superCategory: "Spas",

    //   services: [
    //     {
    //       name: "massage",
    //       category: "regular-spa",
    //       subCategory: "men",
    //       superCategory: "Spas",
    //     },

    //     {
    //       name: "full-massage",
    //       category: "regular-spa",
    //       subCategory: "men",
    //       superCategory: "Spas",
    //     },
    //   ],
    // },

    {
      superCategory: "regular",
      category: "Hair Essentials",
      subCategory: "men",
      services: [
        {
          superCategory: "regular",
          name: "Hair Cut",
          category: "Hair Essentials",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Kids Hair Cut",
          category: "Hair Essentials",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Shaving/beard trim",
          category: "Hair Essentials",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Styling beard",
          category: "Hair Essentials",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Hair wash",
          category: "Hair Essentials",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Hair setting",
          category: "Hair Essentials",
          subCategory: "men",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Hair Colouring",
      subCategory: "men",
      services: [
        {
          superCategory: "regular",
          name: "Base colouring",
          category: "Hair Colouring",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Ammonia free",
          category: "Hair Colouring",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Fashion colouring",
          category: "Hair Colouring",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Streaks",
          category: "Hair Colouring",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Beard colouring",
          category: "Hair Colouring",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Moustache colouring",
          category: "Hair Colouring",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Global highlights",
          category: "Hair Colouring",
          subCategory: "men",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Relaxation",
      subCategory: "men",
      services: [
        {
          superCategory: "regular",
          name: "Protein oil",
          category: "Relaxation",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Olive oil",
          category: "Relaxation",
          subCategory: "men",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Hair Treatments",
      subCategory: "men",
      services: [
        {
          superCategory: "regular",
          name: "Hair spa",
          category: "Hair Treatments",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Beard spa",
          category: "Hair Treatments",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Anti dandruff treatment",
          category: "Hair Treatments",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Anti hair fall treatment",
          category: "Hair Treatments",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Keratin treatment",
          category: "Hair Treatments",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Hair straightening",
          category: "Hair Treatments",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Hair smoothening",
          category: "Hair Treatments",
          subCategory: "men",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Manicure",
      subCategory: "men",
      services: [
        {
          superCategory: "regular",
          name: "Basic manicure",
          category: "Manicure",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Spa manicure",
          category: "Manicure",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Crystal manicure",
          category: "Manicure",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "D-tan manicure",
          category: "Manicure",
          subCategory: "men",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Pedicure",
      subCategory: "men",
      services: [
        {
          superCategory: "regular",
          name: "Basic pedicure",
          category: "Pedicure",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Spa pedicure",
          category: "Pedicure",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Crystal pedicure",
          category: "Pedicure",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "D-tan pedicure",
          category: "Pedicure",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Foot massage",
          category: "Pedicure",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Leg massage",
          category: "Pedicure",
          subCategory: "men",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "D-tan Pack",
      subCategory: "men",
      services: [
        {
          superCategory: "regular",
          name: "Face tan pack",
          category: "D-tan Pack",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "O3d-tan",
          category: "D-tan Pack",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Half arms tan pack",
          category: "D-tan Pack",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Full arms tan pack",
          category: "D-tan Pack",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Full legs tan pack",
          category: "D-tan Pack",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Half legs tan pack",
          category: "D-tan Pack",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Tightening pack",
          category: "D-tan Pack",
          subCategory: "men",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Clean Ups",
      subCategory: "men",
      services: [
        {
          superCategory: "regular",
          name: "Basic clean up",
          category: "Clean Ups",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Insta glow clean up",
          category: "Clean Ups",
          subCategory: "men",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Facials",
      subCategory: "men",
      services: [
        {
          superCategory: "regular",
          name: "Fruit facial",
          category: "Facials",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Silver facial",
          category: "Facials",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Diamond facial",
          category: "Facials",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Gold facial",
          category: "Facials",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Skin whitening & brightening",
          category: "Facials",
          subCategory: "men",
        },
      ],
    },
    {
      superCategory: "regular",
      category: "Packages",
      subCategory: "men",
      services: [
        {
          superCategory: "regular",
          name: "Haircut + shaving",
          category: "Packages",
          subCategory: "men",
        },
        {
          superCategory: "regular",
          name: "Haircut + Shaving + colouring + hair colour",
          category: "Packages",
          subCategory: "men",
        },
      ],
    },
  ],
};
