const options = [
  { id: 0, value: "7:00 AM" },
  { id: 1, value: "7:10 AM" },
  { id: 2, value: "7:20 AM" },
  { id: 3, value: "7:30 AM" },
  { id: 4, value: "7:40 AM" },
  { id: 5, value: "7:50 AM" },
  { id: 6, value: "8:00 AM" },
  { id: 7, value: "8:10 AM" },
  { id: 8, value: "8:20 AM" },
  { id: 9, value: "8:30 AM" },
  { id: 10, value: "8:40 AM" },
  { id: 11, value: "8:50 AM" },
  { id: 12, value: "9:00 AM" },
  { id: 13, value: "9:10 AM" },
  { id: 14, value: "9:20 AM" },
  { id: 15, value: "9:30 AM" },
  { id: 16, value: "9:40 AM" },
  { id: 17, value: "9:50 AM" },
  { id: 18, value: "10:00 AM" },
  { id: 19, value: "10:10 AM" },
  { id: 20, value: "10:20 AM" },
  { id: 21, value: "10:30 AM" },
  { id: 22, value: "10:40 AM" },
  { id: 23, value: "10:50 AM" },
  { id: 24, value: "11:00 AM" },
  { id: 25, value: "11:10 AM" },
  { id: 26, value: "11:20 AM" },
  { id: 27, value: "11:30 AM" },
  { id: 28, value: "11:40 AM" },
  { id: 29, value: "11:50 AM" },
  { id: 30, value: "12:00 PM" },
  { id: 31, value: "12:10 PM" },
  { id: 32, value: "12:20 PM" },
  { id: 33, value: "12:30 PM" },
  { id: 34, value: "12:40 PM" },
  { id: 35, value: "12:50 PM" },
  { id: 36, value: "1:00 PM" },
  { id: 37, value: "1:10 PM" },
  { id: 38, value: "1:20 PM" },
  { id: 39, value: "1:30 PM" },
  { id: 40, value: "1:40 PM" },
  { id: 41, value: "1:50 PM" },
  { id: 42, value: "2:00 PM" },
  { id: 43, value: "2:10 PM" },
  { id: 44, value: "2:20 PM" },
  { id: 45, value: "2:30 PM" },
  { id: 46, value: "2:40 PM" },
  { id: 47, value: "2:50 PM" },
  { id: 48, value: "3:00 PM" },
  { id: 49, value: "3:10 PM" },
  { id: 50, value: "3:20 PM" },
  { id: 51, value: "3:30 PM" },
  { id: 52, value: "3:40 PM" },
  { id: 53, value: "3:50 PM" },
  { id: 54, value: "4:00 PM" },
  { id: 55, value: "4:10 PM" },
  { id: 56, value: "4:20 PM" },
  { id: 57, value: "4:30 PM" },
  { id: 58, value: "4:40 PM" },
  { id: 59, value: "4:50 PM" },
  { id: 60, value: "5:00 PM" },
  { id: 61, value: "5:10 PM" },
  { id: 62, value: "5:20 PM" },
  { id: 63, value: "5:30 PM" },
  { id: 64, value: "5:40 PM" },
  { id: 65, value: "5:50 PM" },
  { id: 66, value: "6:00 PM" },
  { id: 67, value: "6:10 PM" },
  { id: 68, value: "6:20 PM" },
  { id: 69, value: "6:30 PM" },
  { id: 70, value: "6:40 PM" },
  { id: 71, value: "6:50 PM" },
  { id: 72, value: "7:00 PM" },
  { id: 73, value: "7:10 PM" },
  { id: 74, value: "7:20 PM" },
  { id: 75, value: "7:30 PM" },
  { id: 76, value: "7:40 PM" },
  { id: 77, value: "7:50 PM" },
  { id: 78, value: "8:00 PM" },
  { id: 79, value: "8:10 PM" },
  { id: 80, value: "8:20 PM" },
  { id: 81, value: "8:30 PM" },
  { id: 82, value: "8:40 PM" },
  { id: 83, value: "8:50 PM" },
  { id: 84, value: "9:00 PM" },
  { id: 85, value: "9:10 PM" },
  { id: 86, value: "9:20 PM" },
  { id: 87, value: "9:30 PM" },
  { id: 88, value: "9:40 PM" },
  { id: 89, value: "9:50 PM" },
  { id: 90, value: "10:00 PM" },
];

export default options;
