export const spaCategories = {
  men: [
    {
      category: "regular",
      subCategory: "men",
      services: [
        {
          name: "massage",
          category: "regular-spa",
          subCategory: "men",
        },

        {
          name: "full-massage",
          category: "regular-spa",
          subCategory: "men",
        },
      ],
    },
  ],
  women: [
    {
      category: "regular",
      subCategory: "women",
      services: [
        {
          name: "massage",
          category: "regular-spa",
          subCategory: "women",
        },
        {
          name: "full-massage",
          category: "regular-spa",
          subCategory: "women",
        },
      ],
    },
  ],
};
